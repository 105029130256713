import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: {
      databaseId: string
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, seo, databaseId },
  },
  location = {},
  prefix = 'post_Flexcontent',
  pageContext,
}) => (
  <Layout>
    <SEO seo={seo} />
    <PostContext.Provider value={{ databaseId }}>
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </PostContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query postById($id: String!) {
    page: wpPost(id: { eq: $id }) {
      ...generalPostFragment
    }
  }
`

export interface PostContextProps {
  databaseId?: number
}

export const PostContext = React.createContext({
  databaseId: '',
})

export default PageTemplate
